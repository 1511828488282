import "@mantine/core/styles.css";
import { AuthContextProvider } from "./context/AuthContext";
import OurContextProvider from "./context/OurContext";

import cx from "clsx";
import { MantineProvider, Container, createTheme, em } from "@mantine/core";
// import classes from "./componets/mantine_css/Demo.module.css";

import MyApp from "./MyApp";
const theme = createTheme({
  // breakpoints: {
  //   xs: "30em",
  //   sm: "48em",
  //   md: "64em",
  //   lg: "74em",
  //   xl: "90em",
  // },

  // spacing: {
  //   xs: '0.0 rem',
  //   sm: '0.75rem',
  //   md: '4rem',
  //   // lg: '1.5rem',
  //   // xl: '2rem',
  // },

  // components: {
  //   Container: Container.extend({
  //     classNames: (_, { size }) => ({
  //       root: cx({ [classes.responsiveContainer]: size === "responsive" }),
  //     }),
  //   }),
  // },
  /** Put your mantine theme override here */
});

function App() {
  return (
    <div className="App">
      <MantineProvider theme={theme}>
        <AuthContextProvider>
          <OurContextProvider>
            <Container 
            
            px={0}
            size="responsive" 
              // bg={"green"} 
            >
              <MyApp />
            </Container>
          </OurContextProvider>
        </AuthContextProvider>
      </MantineProvider>
    </div>
  );
}

export default App;
