import "./index.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useAuthContext } from "./hooks/useAuthContext";
import HelmetTags from "./componets/HelmetTags";
import { Routes, Route, Navigate } from "react-router-dom";
import ComprarPlanPage from "./componets/ComprarPlanPage";
import AuthVerify from "./componets/AuthVerify";
import { TestDetailedView } from "./componets/TestDetailedView";
import { MyRequestEmailConfirmation } from "./componets/MyRequestEmailConfirmation";

import { AppShell, Box } from "@mantine/core";

import { OurContext } from "./context/OurContext";
import { useContext } from "react";
import { MyHeader } from "./componets/MyHeader";
import { Mysingup } from "./componets/Mysingup";
import { Mylogin } from "./componets/Mylogin";
import { MyforgotPassword } from "./componets/MyForgotPassword";
import { MyResetPassword } from "./componets/MyResetPassword";
import { MyAddAnuncioForm } from "./componets/MyAddAnuncioForm";
import { MyMainPage } from "./componets/MyMainPage";
import { MyAds } from "./componets/MyAds";
import { MyFooter } from "./componets/MyFooter";
import { MyEmailConfirmed } from "./componets/MyEmailConfirmed";
function MyApp() {
  const { user } = useAuthContext();

  const { miPlan, setMiPlan, setAnunciosDisponibles, anuncioElegido } =
    useContext(OurContext);

  return (
    <AppShell
      padding={{ base: 0, md: 30 }}
    
      // padding="md"
    >
      <HelmetTags />
      <MyHeader />


    {/* <Box >kjkjh</Box> */}

      <AppShell.Main mt={80}>
        {" "}
        <Routes>
          <Route path="/" element={<MyMainPage />} />

          <Route
            path="/login"
            element={!user ? <Mylogin /> : <Navigate to="/" />}
          />
          <Route
            path="/signup"
            element={!user ? <Mysingup /> : <Navigate to="/" />}
          />

          <Route path="/forgotPassword" element={<MyforgotPassword />} />
          <Route
            path="/resetPassword/:id/:token"
            element={<MyResetPassword />}
          />
          <Route
            path="/confirmEmail/:id/:token"
            element={<MyEmailConfirmed />}
          />
          <Route
            path="/requestEmailConfirmation"
            element={<MyRequestEmailConfirmation />}
          />

          <Route path="/comprarPlanPage" element={<ComprarPlanPage />} />
          <Route
            path="/anuncioDetailedView/:anuncioId"
            element={<TestDetailedView />}
          />

          <Route
            path="/misAnuncios"
            element={user ? <MyAds /> : <Navigate to="/" />}
          />

          <Route
            path="/agregarAnuncio"
            element={user ? <MyAddAnuncioForm /> : <Navigate to="/" />}
          />
        </Routes>
      </AppShell.Main>

      <AuthVerify />
      <MyFooter />
    </AppShell>
  );
}

export default MyApp;
