import {
  Image,
  Card,
  Text,
  Group,
  Button,
  rem,
  NumberFormatter,
  Center,
  Anchor,
} from "@mantine/core";
import React, { useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { Carousel } from "@mantine/carousel";

import "@mantine/carousel/styles.css";

import mappingEmpresa from "./Empresas";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useState } from "react";
import classes from "./mantine_css/CarouselCard.module.css";
import Map, { Marker } from "react-map-gl";
import axios from "axios";
import serverUrl from "../GetServerUrl";
import { useParams } from "react-router-dom";

import CheckBoxIcon from "@mui/icons-material/CheckBox";

import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
const TOKEN =
  "pk.eyJ1IjoiaXZhbmxlYm92a2EiLCJhIjoiY2xpNXhka24zMWtndTNsbHBsZ3RibG9nbyJ9.RdfUV9MYNaHyuGI_IfOd3A";
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
}

export function TestDetailedView(props) {
  let { anuncioId } = useParams();

  // const anuncioId = "64e110aee7050e456899b96f";

  const [anuncioToShow, setAnuncioToShow] = useState(null);

  const [showMobile, setShowMobile] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  let donde = "";

  useEffect(() => {
    axios.get(`${serverUrl}/anuncio/${anuncioId}`, {}).then((response) => {
      console.log("response.data", response.data);
      if (Object.keys(response.data).includes("message")) {
        return;
      }
      setAnuncioToShow(response.data);
    });
  }, []);

  if (!anuncioToShow) {
    return <Text>Por Favor, regresa a la pagina principal</Text>;
  }

  let empresa = "";

  if (anuncioToShow.source) {
    empresa = "Empresa:" + " " + mappingEmpresa[anuncioToShow.source];
  }

  if (anuncioToShow.locality) {
    donde = donde + anuncioToShow.locality;
  }

  if (anuncioToShow.locality && anuncioToShow.place) {
    donde = donde + ", " + anuncioToShow.place;
  }

  if (!anuncioToShow.locality && anuncioToShow.place) {
    donde = donde + anuncioToShow.place;
  }

  if ((anuncioToShow.place || anuncioToShow.locality) && anuncioToShow.region) {
    donde = donde + ", " + anuncioToShow.region;
  }

  if (
    !(anuncioToShow.place || anuncioToShow.locality) &&
    anuncioToShow.region
  ) {
    donde = donde + anuncioToShow.region;
  }

  if (donde === "") {
    donde = "República Dominicana";
  }

  const slides = anuncioToShow.image_urls.map((image_url, index) => (
    <Carousel.Slide key={index}>
      <Image
        src={image_url}
        height={600}
        //  w="auto"
        fit="contain"
      />
    </Carousel.Slide>
  ));

  return (
    <Center>
      {/* below for desktop */}
      <Group justify="center" w={"60%"} visibleFrom="sm">
        {/* <NavigationProgress /> */}
        {anuncioToShow && (
          <Card radius="md" withBorder padding="xl">
            <Card.Section>
              <Carousel
                // height={400}
                withIndicators
                loop
                classNames={{
                  root: classes.carousel,
                  controls: classes.carouselControls,
                  indicator: classes.carouselIndicator,
                }}
              >
                {slides}
              </Carousel>
            </Card.Section>

            <Group justify="space-between" mt="lg">
              <Text fw={500} fz="lg">
                {anuncioToShow.title}
              </Text>
            </Group>

            <Group gap={6}>
              {anuncioToShow.proposito && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.proposito.toUpperCase()}
                </Text>
              )}
              {anuncioToShow.propiedad && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.propiedad}
                </Text>
              )}

              {anuncioToShow.metros && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.metros + "m²"}
                </Text>
              )}

              {anuncioToShow.habitaciones && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.habitaciones + " hab."}
                </Text>
              )}

              {anuncioToShow.banos && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.banos + " bañ."}
                </Text>
              )}

              {anuncioToShow.nivel && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.nivel + " nivel"}
                </Text>
              )}
            </Group>

            <Group justify="space-between" mt="md">
              <div>
                {/* <Text span fz="sm">
                {anuncioToShow.moneda}
              </Text>
              <Text fz="xl" span fw={500} className={classes.price}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                }).format(anuncioToShow.price)}
              </Text> */}
                <NumberFormatter
                  prefix={anuncioToShow.moneda + "$ "}
                  value={anuncioToShow.price}
                  thousandSeparator
                />
              </div>
            </Group>

            <Group gap={3}>
              {/* <FaLocationDot color="green" /> */}
              <Text fz="sm" mt="sm">
                📍
                {donde}
              </Text>
            </Group>

            <Group gap={3}>
              {anuncioToShow.balcon && (
                <Text fz="sm" mt="sm">
                  Balcon
                  <CheckIcon color={"success"} />
                </Text>
              )}

              {anuncioToShow.patio && (
                <Text fz="sm" mt="sm">
                  Patio
                  <CheckIcon color={"success"} />
                </Text>
              )}

              {anuncioToShow.piscina && (
                <Text fz="sm" mt="sm">
                  Piscina
                  <CheckIcon color={"success"} />
                </Text>
              )}
              {anuncioToShow.parqueo && (
                <Text fz="sm" mt="sm">
                  Parqueo
                  <CheckIcon color={"success"} />
                </Text>
              )}
            </Group>

            {anuncioToShow.description && (
              <Group gap={"xs"}>
                {anuncioToShow.description
                  .split("\n")
                  .map((unaLinea, index) => {
                    return (
                      <Text fz="sm" mt="sm" key={index}>
                        {unaLinea}
                      </Text>
                    );
                  })}
              </Group>
            )}
            <Group pt={3}>
              <Text>{empresa}</Text>
            </Group>

            <Group pt={3}>
              <Text> {"Asesor: " + anuncioToShow.name}</Text>
            </Group>

            <Group mt="md">
              {!showEmail && (
                <Button
                  color="#ff8a65"
                  // onClick={() => nprogress.start(showDetailedView(item))}
                  onClick={() => {
                    setShowEmail(true);
                  }}
                  radius="md"
                >
                  Email
                </Button>
              )}
              {showEmail && (
                <Anchor
                  color="#ff8a65"
                  // onClick={() => nprogress.start(showDetailedView(item))}
                  href={`mailto:${anuncioToShow.email}`}
                  radius="md"
                >
                  {" "}
                  {anuncioToShow.email}{" "}
                </Anchor>
              )}

              {!showMobile && (
                <Button
                  color="#ff8a65"
                  onClick={() => {
                    setShowMobile(true);
                    // console.log("$$$", item);
                  }}
                  radius="md"
                >
                  Contacto
                </Button>
              )}
              {showMobile && (
                <Anchor
                  color="#ff8a65"
                  variant="subtle"
                  href={`tel:${anuncioToShow.movil}`}
                  radius="md"
                >
                  {formatPhoneNumber(anuncioToShow.movil)}
                </Anchor>
              )}
            </Group>

            <Group>
              {props.queHacerOnDelete2 && (
                <LoadingButton
                  loading={props.isLoading}
                  aria-label="delete"
                  size="large"
                  onClick={() => props.queHacerOnDelete2(anuncioToShow._id)}
                >
                  <DeleteIcon
                    // fontSize="inherit"
                    size="large"
                  />
                </LoadingButton>
              )}
            </Group>

            <Group pt={20} grow>
              <Map
                initialViewState={{
                  longitude: anuncioToShow.longitude,
                  latitude: anuncioToShow.latitude,
                  zoom: 13,
                }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken={TOKEN}
                style={{ height: "500px" }}
              >
                <Marker
                  longitude={anuncioToShow.longitude}
                  latitude={anuncioToShow.latitude}
                  anchor="bottom"
                />
              </Map>
            </Group>
          </Card>
        )}
        {!anuncioToShow && <div> Algo no esta bien</div>}
      </Group>
      {/* below for mobile */}
      <Group grow justify="center" hiddenFrom="sm">
        {/* <NavigationProgress /> */}
        {anuncioToShow && (
          <Card withBorder>
            <Card.Section>
              <Carousel
                // height={400}
                withIndicators
                loop
                classNames={{
                  root: classes.carousel,
                  controls: classes.carouselControls,
                  indicator: classes.carouselIndicator,
                }}
              >
                {anuncioToShow.image_urls.map((image_url, index) => (
                  <Carousel.Slide key={index}>
                    <Image src={image_url} height={300} fit="contain" />
                  </Carousel.Slide>
                ))}
              </Carousel>
            </Card.Section>

            <Group justify="space-between" mt="lg">
              <Text fw={500} fz="lg">
                {anuncioToShow.title}
              </Text>
            </Group>

            <Group gap={6}>
              {anuncioToShow.proposito && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.proposito.toUpperCase()}
                </Text>
              )}
              {anuncioToShow.propiedad && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.propiedad}
                </Text>
              )}

              {anuncioToShow.metros && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.metros + "m²"}
                </Text>
              )}

              {anuncioToShow.habitaciones && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.habitaciones + " hab."}
                </Text>
              )}

              {anuncioToShow.banos && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.banos + " bañ."}
                </Text>
              )}

              {anuncioToShow.nivel && (
                <Text fz="xs" fw={500}>
                  {anuncioToShow.nivel + " nivel"}
                </Text>
              )}
            </Group>

            <Group justify="space-between" mt="md">
              <div>
                <NumberFormatter
                  prefix={anuncioToShow.moneda + "$ "}
                  value={anuncioToShow.price}
                  thousandSeparator
                />
              </div>
            </Group>

            <Group gap={3}>
              {/* <FaLocationDot color="green" /> */}
              <Text fz="sm" mt="sm">
                📍
                {donde}
              </Text>
            </Group>

            <Group
            // gap={3}
            >
              {anuncioToShow.balcon && (
                <Group justify="center">
                  <Text fz="xs" mr={-15}>
                    Balcon
                  </Text>
                  <CheckBoxIcon color={"success"} />
                </Group>
              )}

              {anuncioToShow.patio && (
                <Group>
                  <Text fz="xs" mr={-15}>
                    Patio
                  </Text>
                  <CheckBoxIcon color={"success"} />
                </Group>
              )}

              {anuncioToShow.piscina && (
                <Group>
                  <Text fz="xs" mr={-15}>
                    Piscina
                  </Text>
                  <CheckBoxIcon color={"success"} />
                </Group>
              )}
              {anuncioToShow.parqueo && (
                <Group>
                  <Text fz="xs" mr={-15}>
                    Parqueo
                  </Text>
                  <CheckBoxIcon color={"success"} />
                </Group>
              )}
            </Group>

            {anuncioToShow.description && (
              <Group gap={"xs"}>
                {anuncioToShow.description
                  .split("\n")
                  .map((unaLinea, index) => {
                    return (
                      <Text fz="sm" mt="sm" key={index}>
                        {unaLinea}
                      </Text>
                    );
                  })}
              </Group>
            )}
            <Group pt={3}>
              <Text>{empresa}</Text>
            </Group>

            <Group pt={3}>
              <Text> {"Asesor: " + anuncioToShow.name}</Text>
            </Group>

            <Group mt="md">
              {!showEmail && (
                <Button
                  color="#ff8a65"
                  // onClick={() => nprogress.start(showDetailedView(item))}
                  onClick={() => {
                    setShowEmail(true);
                  }}
                  radius="md"
                >
                  Email
                </Button>
              )}
              {showEmail && (
                <Anchor
                  color="#ff8a65"
                  // onClick={() => nprogress.start(showDetailedView(item))}
                  href={`mailto:${anuncioToShow.email}`}
                  radius="md"
                >
                  {" "}
                  {anuncioToShow.email}{" "}
                </Anchor>
              )}

              {!showMobile && (
                <Button
                  color="#ff8a65"
                  onClick={() => {
                    setShowMobile(true);
                    // console.log("$$$", item);
                  }}
                  radius="md"
                >
                  Contacto
                </Button>
              )}
              {showMobile && (
                <Anchor
                  color="#ff8a65"
                  variant="subtle"
                  href={`tel:${anuncioToShow.movil}`}
                  radius="md"
                >
                  {formatPhoneNumber(anuncioToShow.movil)}
                </Anchor>
              )}
            </Group>

            <Group>
              {props.queHacerOnDelete2 && (
                <LoadingButton
                  loading={props.isLoading}
                  aria-label="delete"
                  size="large"
                  onClick={() => props.queHacerOnDelete2(anuncioToShow._id)}
                >
                  <DeleteIcon
                    // fontSize="inherit"
                    size="large"
                  />
                </LoadingButton>
              )}
            </Group>

            <Group pt={20} grow>
              <Map
                initialViewState={{
                  longitude: anuncioToShow.longitude,
                  latitude: anuncioToShow.latitude,
                  zoom: 13,
                }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken={TOKEN}
                style={{ height: "500px" }}
              >
                <Marker
                  longitude={anuncioToShow.longitude}
                  latitude={anuncioToShow.latitude}
                  anchor="bottom"
                />
              </Map>
            </Group>
          </Card>
        )}
        {!anuncioToShow && <div> Algo no esta bien</div>}
      </Group>
    </Center>
  );
}
